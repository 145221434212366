// Colors
$brand-primary:	#a59c89;
$team-building:	#c7bca5;

// Scaffolding
$text-color: 		#000;
$link-color: 		#000;
$text-bloc: 		#746e60;
$text-actu:			#676154;
$link-hover-color:	$brand-primary;

// Typography
$font-family-sans-serif:	'DINPro-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family: 		'DINPro-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
$actus-font-family:			"Akkurat", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-weight:		400;
$headings-line-height:		1.2;
$font-size-base:          	16px;
$font-size-h1:            	floor(($font-size-base * 1.75)) !default; // ~28px
$font-size-h2:            	floor(($font-size-base * 1.5)) !default; // ~24px
$font-size-h3:            	ceil(($font-size-base * 1.25)) !default; // ~20px
$font-size-h4:            	ceil(($font-size-base * 1.125)) !default; // ~18px
$font-size-h5:            	$font-size-base !default;
$font-size-h6:            	ceil(($font-size-base * 0.875)) !default; // ~14px
$line-height-base:			1.625; // 26/16