body.formulaire-de-contact, body.contact-form {
  #top-bar {
    .navbar-header {
      .page-title-wrapper {
        h1 {
          font-size: 1em; // 16px

          @media only screen and (min-width: $screen-sm-min) {
            font-size: 1.25em; // 20px
          }

          @media only screen and (min-width: $screen-md-min) {
            font-size: 1.75em; // 28px
          }
          text-align: center;
        }
      }
    }
  }
}
