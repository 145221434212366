@media only screen and (min-width: $screen-sm-min) {
    #cookie-notice {
        width: 25% !important;
        min-width: 0;
        right: 0;
        left: unset;
        margin: 0 12px 12px 0;
        a {
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}