/*html.no-scroll {
  #main {
    overflow: hidden !important;
    position: fixed;
    width: 100%;
  }
}*/

/*.sub-section {
  // Max : sur mobile, les sub-section sont en fixed pour se caler indépendamment du scroll dans la page
  position: fixed;
  //opacity:.6; // Max : test
  .section-content {
    .controls {
      top: 75px;
    }
  }
  @media only screen and (min-width: 768px) {
    position: absolute;
  }
}*/

/*#global-overlay-loader {
  position: fixed;
}*/

/*#grid-espaces, #grid-espaces-filtered {
  .grid-row { // row
    margin: 0;
    .grid-col { // col
      padding: 0;
      .grid-items { // row
        margin: 0;
        .grid-item { // col
          position: relative;
          padding: 0;
          div { // s'applique aux row et aux col enfants de grid-item
            height: 100%;
            margin: 0;
            padding: 0;
          }
          .grid-item-bg {
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            background-color: #fff;
          }
          .grid-item-title {
            padding-left: 15px;
            @media only screen and (min-width: 768px) {
              padding: 0;
              display: none;
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
          &:hover {
            .grid-item-title-sm {
              @media only screen and (min-width: 768px) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}*/

/*.grid-scroll-wrap {
  padding-bottom: 40px;
}*/

/*#espace-detail {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 60px;
  left: 0;
  z-index: 275;
  padding: 15px 0;
  @media only screen and (min-width: 768px) {
    padding: 30px 0;
  }
}*/

/*body.espace {
  // Max -> Manu : créer les mêmes règles que body.subSection pour que #top-bar .page-title-wrapper soit décalé lorsqu'on est sur une fiche espace
}*/

/*.brand { // Max : classe pour mettre du texte en couleur, ajoutée via le Rich Text Editor dans WP
  color: $brand-primary;
}*/

/*#espace-detail .entry .title {
  margin-bottom: 5px; // au lieu de 15px
  line-height: 1.2em;
  font-size: 0.9em;
  letter-spacing: -0.05em;
}*/

/*#espace-detail .entry .infos .mCSB_container {
  padding-bottom: 40px;
}*/

/*.sub-section .controls {
  top: -35px; // au lieu de -25px
}*/

/*#espace-detail {
  padding-top: 10px;
  @media only screen and (min-width: 992px) {
    padding-top: 20px;
  }
}*/

/*#espace-detail .entry .title {
  margin-top: 0;
  margin-bottom: 20px;
}*/

/*#std-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 60px;
  left: 0;
  z-index: 2000;
  padding: 15px 0;
  @media only screen and (min-width: 768px) {
    padding: 30px 0;
  }
}*/

/*.media-viewer .photos .items {
  overflow: hidden;
}*/

/*#espace-detail .entry .media-viewer {
  .caption {
    @media only screen and (min-width: 768px) {
      padding: 0 140px;
    }
    margin-top: 6px;
  }
  .controls {
    a {
      bottom: -2px; // pour annuler ta valeur bottom: 15px
    }
  }
  iframe {
    display: block; // sinon y a un espace généré par le <p> qui entoure l'iframe
  }
  .photos {
    position: relative;
    .photo-wrap {
      @media only screen and (min-width: 768px) {
        overflow: hidden;
      }
    }
    .controls {
      @media only screen and (min-width: 768px) {
        position: absolute;
        width: 100%;
        top: 0; // sera calculé en JS
        margin-top: 6px;
        a {
          bottom: auto; // pour annuler ta valeur bottom: 15px (uniquement si > 768)
          left: 0;
          &.btn-next {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}*/

/*.sub-section.les-espaces #grid-espaces-filtered .grid-scroll-wrap .grid-row .grid-col .grid-items .grid-item {
  &.hidden-filtered {
    display: none !important;
  }
}*/

/*#std-page {
  .pane-body {
    iframe {
      display: block;
      margin: 0 auto;
      max-width: 600px;
      @media only screen and (min-width: 768px) {
        max-width: 800px;
      }
    }
  }
}*/

/*#share-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: rgba(0, 0, 0, .6);
}*/

#lang_sel_list.lang_sel_list_horizontal {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #a59c89;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: inline-block;
      font-size: .75em;
      &:first-child {
        &:after {
          content: "|";
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}

#alert-screen-orientation {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999999;
  padding: 20%;
  text-align: center;
}