// Background-size
@mixin background-size($width, $height) {
  -webkit-background-size: $width, $height;
  -moz-background-size: $width, $height;
  background-size: $width, $height;
}

// HiDPI
$is-hidpi: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx)";

@mixin retinize($file, $type, $width, $height) {
  background-image: url('../images/' + $file + '.' + $type);

  @media #{$is-hidpi} {
    & {
      background-image: url('../images/' + $file + '@2x.' + $type);
      @include background-size($width, $height);
    }
  }
}

// Uppercase text
@mixin uppercase() {
  text-transform: uppercase;
  letter-spacing: .05em;
}

@mixin separator() {
  display: block;
  margin-bottom: -15px;
  &:before {
    content: "";
    display: block;
    background-color: $brand-primary;
    @include size(60px, 10px);
  }
}
