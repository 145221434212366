/*********************
01. GENERAL STYLES
*********************/

body {
  &.admin-bar {
    #offcanvas-menu {
      margin-top: 32px;
    }
  }

  &.admin-bar:not(.menu-open) {
    #top-bar {
      margin-top: 32px;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

/*** Fonts ***/
@font-face {
  font-family: 'Akkurat';
  src: url('../fonts/Akkurat.eot');
  src: url('../fonts/Akkurat.woff2') format('woff2'),
  url('../fonts/Akkurat.woff') format('woff'),
  url('../fonts/Akkurat.ttf') format('truetype'),
  url('../fonts/Akkurat.svg#Akkurat') format('svg'),
  url('../fonts/Akkurat.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat-Light';
  src: url('../fonts/Akkurat-Light.eot');
  src: url('../fonts/Akkurat-Light.woff2') format('woff2'),
  url('../fonts/Akkurat-Light.woff') format('woff'),
  url('../fonts/Akkurat-Light.ttf') format('truetype'),
  url('../fonts/Akkurat-Light.svg#Akkurat-Light') format('svg'),
  url('../fonts/Akkurat-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat-Bold';
  src: url('../fonts/Akkurat-Bold.eot');
  src: url('../fonts/Akkurat-Bold.woff2') format('woff2'),
  url('../fonts/Akkurat-Bold.woff') format('woff'),
  url('../fonts/Akkurat-Bold.ttf') format('truetype'),
  url('../fonts/Akkurat-Bold.svg#Akkurat-Bold') format('svg'),
  url('../fonts/Akkurat-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Regular';
  src: url('../fonts/DINPro-Regular.eot');
  src: url('../fonts/DINPro-Regular.woff2') format('woff2'),
  url('../fonts/DINPro-Regular.woff') format('woff'),
  url('../fonts/DINPro-Regular.ttf') format('truetype'),
  url('../fonts/DINPro-Regular.svg#DINPro-Regular') format('svg'),
  url('../fonts/DINPro-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Light';
  src: url('../fonts/DINPro-Light.eot');
  src: url('../fonts/DINPro-Light.woff2') format('woff2'),
  url('../fonts/DINPro-Light.woff') format('woff'),
  url('../fonts/DINPro-Light.ttf') format('truetype'),
  url('../fonts/DINPro-Light.svg#DINPro-Light') format('svg'),
  url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url('../fonts/DINPro-Medium.eot');
  src: url('../fonts/DINPro-Medium.woff2') format('woff2'),
  url('../fonts/DINPro-Medium.woff') format('woff'),
  url('../fonts/DINPro-Medium.ttf') format('truetype'),
  url('../fonts/DINPro-Medium.svg#DINPro-Medium') format('svg'),
  url('../fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Bold';
  src: url('../fonts/DINPro-Bold.eot');
  src: url('../fonts/DINPro-Bold.woff2') format('woff2'),
  url('../fonts/DINPro-Bold.woff') format('woff'),
  url('../fonts/DINPro-Bold.ttf') format('truetype'),
  url('../fonts/DINPro-Bold.svg#DINPro-Bold') format('svg'),
  url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Black';
  src: url('../fonts/DINPro-Black.eot');
  src: url('../fonts/DINPro-Black.woff2') format('woff2'),
  url('../fonts/DINPro-Black.woff') format('woff'),
  url('../fonts/DINPro-Black.ttf') format('truetype'),
  url('../fonts/DINPro-Black.svg#DINPro-Black') format('svg'),
  url('../fonts/DINPro-Black.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

/*** GForm ***/
.gform_wrapper {
  label {
    font-weight: normal;
    font-size: .875em; // 14px
    margin: 0;
  }

  input, textarea {
    font-size: 14px;
    border: none;
    border-bottom: 2px solid rgba($brand-primary, .3);
    border-radius: 0;
    box-shadow: none;
    height: 30px;
    padding: 2px 10px;

    &:focus {
      box-shadow: none;
    }
  }

  textarea {
    height: 50px;
  }

  input[type=submit] {
    @include uppercase();
    background-color: #000;
    color: #e4e1db;
    height: auto;
    line-height: $headings-line-height;
    padding: 4px 4px 3px;
    margin-right: 10px;

    &:hover, &:focus {
      background-color: $text-bloc;
    }
  }

  .gfield_description {
    font-size: .6875em; // 11px
    font-style: italic;
  }

  .gfield_required {
    color: #000;
  }

  #gforms_confirmation_message, .alert, .validation_error {
    padding: 5px;
  }

  .gf_list_inline {
    li {
      display: inline-block;

      input, label {
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-right: 10px;
      }

      input {
        margin-top: 1px;
      }

      + li {
        margin-left: 20px;
      }
    }
  }

  .gform_validation_container {
    display: none;
  }
}

/*** Scrollbar ***/
.mCustomScrollbar {
  .content {
    padding-bottom: 30px;
  }

  .mCSB_scrollTools {
    @include opacity(1);

    .mCSB_dragger {
      .mCSB_dragger_bar {
        background-color: $brand-primary;
        width: 6px;
      }

      &:hover, &:active {
        .mCSB_dragger_bar {
          background-color: $brand-primary;
        }
      }
    }

    .mCSB_draggerRail {
      background-color: #e6e6e6;
      width: 6px;
    }
  }
}

/*** Loader ***/
#global-overlay-loader {
  width: 100%;
  height: 100%;
  background: $brand-primary;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000;
}

.loader {
  position: absolute;

  .sk-folding-cube {
    float: none !important;

    .sk-cube:before {
      background-color: $brand-primary !important;
    }
  }

  span {
    display: block;
    font-family: $font-family-sans-serif;
    font-size: .75em;
    color: $brand-primary;
    text-align: center;
  }

  &.loader-white {
    left: 50%;
    top: 50%;
    @include size(160px, 70px);
    margin: -35px 0 0 -80px;

    .sk-folding-cube .sk-cube:before {
      background-color: #fff !important;
    }

    span {
      color: #fff;
      position: relative;
      top: 7px;
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    span {
      font-size: .875em; // 14px
    }
  }
}

/*** Rich Text Editor ***/
.brand {
  color: $brand-primary;
}

.txt-large {
  font-size: 24px;
}

.txt-small {
  font-size: 14px;
}

/*** Share Overlay ***/
#share-overlay {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%, 100%);
  z-index: 999999;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  text-align: center;

  .share-content {
    position: absolute;
    left: 50%;
    top: 50%;
    @include size(230px, 130px);
    margin: -65px 0 0 -115px;

    .btn-close-share {
      display: block;
      background-position: left -61px;
      background-repeat: no-repeat;
      @include retinize('sprite', 'png', 286px, 84px);
      @include size(24px, 23px);
      position: relative;
      top: 20px;
      left: 50%;
      margin-left: -12px;

      &:hover, &:focus {
        background-position: -24px -61px;
      }
    }
  }
}

/* Modal Info site Recevoir */
#info-recevoir-overlay {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%, 100%);
  z-index: 999999;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  text-align: center;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (min-width: $screen-sm-min) {
      left: 50%;
      max-width: 512px;
    }
    @media only screen and (min-width: $screen-md-min) {
      max-width: 662px;
    }
    @media only screen and (min-width: $screen-lg-min) {
      max-width: 810px;
    }
    background: rgba(255, 255, 255, .9);
    color: #000;
    font-size: 1.688em; // 27px
    padding: 40px 20px;

    .col {
      &:not(:first-child) {
        margin-top: 20px;
        @media only screen and (min-width: $screen-md-min) {
          margin-top: 0;
        }
      }
    }

    .title {
      line-height: 1em;
      margin-bottom: 0.3em;
    }

    .btn {
      display: inline-block;
      border: 1px solid $brand-primary;
      border-radius: 0;
      padding: 5px 10px;
      font-size: .625em;
      color: $brand-primary;
      font-weight: normal;
      white-space: normal;

      &:hover {
        text-decoration: none;

      }
    }
  }
}

/*********************
02. LAYOUT & GRID STYLES
*********************/

/* Offcanvas menu (Slideout.js) */
.slideout-menu {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 260px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
  left: 0;

  .padded {
    padding: 30px 20px 0 35px;
  }

  .info-recevoir {
    background-color: $brand-primary;
    color: #fff;
    font-size: .625em; // 10px
    height: 60px;
    max-height: 60px;

    .padded {
      padding: 9px 0 3px 15px;

    }

    .content {
      padding-right: 3px;
      border-right: solid 1px #fff;
      line-height: 1.1em;

      .big {
        font-size: 1.7em; // 17px
        margin-bottom: 0.4em;
      }

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.slideout-panel {
  background-color: #fff;
  position: relative;
  z-index: 200;
}

.slideout-open {
  .slideout-menu {
    display: block;
    z-index: 100;
    left: 0;
  }
}

/*********************
03. LINK STYLES
*********************/

a {
  &:link {
    /*
      this highlights links on iPhones/iPads.
      so it basically works like the :hover selector
      for mobile devices.
      */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  }

  &:hover, &:focus {
    text-decoration: none;
    outline: 0;
  }
}

/*********************
04. H1, H2, H3, H4, H5, H6 STYLES
*********************/

/*********************
05. HEADER STYLES
*********************/

body {
  #top-bar {
    background-color: $brand-primary;
    border: none;
    color: #fff;
    height: 60px;
    @include transition(transform 300ms ease);
    margin: 0;

    &.toggle {
      @include translate(260px, 0);
    }

    .navbar-header {
      .burger {
        float: left;
        @include size(60px, 60px);
        padding: 19px 15px;
      }

      .navbar-brand {
        float: none;
        display: inline-block;
        vertical-align: middle;
        background-position: left top;
        background-repeat: no-repeat;
        @include retinize('logo', 'png', 194px, 60px);
        @include size(59px, 60px);
      }

      .page-title-wrapper {
        display: inline-block;
        vertical-align: middle;
        @include transition(margin .6s ease);
        margin-left: 5px;

        .back-to-section {
          display: inline-block;
          vertical-align: middle;
          background-position: -176px -38px;
          background-repeat: no-repeat;
          @include retinize('sprite', 'png', 286px, 84px);
          @include size(14px, 24px);

          &:hover {
            background-position: -190px -38px;
          }

          + .page-title {
            @include transition(margin .9s ease);
            max-width: 105px;
            margin-left: 5px;
          }

          &.hidden + .page-title {
            max-width: 120px;
            margin-left: 0;
          }
        }

        .page-title {
          display: inline-block;
          vertical-align: middle;
          font-family: $font-family-sans-serif;
          font-size: 1em; // 16px
          margin: 0 0 4px;
        }
      }
    }

    .navbar-nav {
      position: absolute;
      right: 15px;
      top: 20px;
      margin: 0;

      > li {
        float: left;

        + li {
          margin-left: 10px;
        }

        &:first-child {
          margin-top: -4px;

          i {
            font-size: 1.5em;
          }
        }

        > a {
          color: #fff;
          padding: 0;

          &:hover {
            color: #000;

            &:focus {
              color: #fff;
            }
          }

          &#btn-open-share-overlay {
            background-position: -267px 0;
            background-repeat: no-repeat;
            @include retinize('sprite', 'png', 286px, 84px);
            @include size(19px, 19px);
            padding: 0;

            &:hover, &:focus {
              background-position: -267px -19px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 480px) {
    #top-bar .navbar-header .page-title-wrapper .back-to-section {
      &.hidden + .page-title {
        max-width: 330px;
      }

      + .page-title {
        max-width: 300px;
      }
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    #top-bar {
      .navbar-header {
        float: left;
        width: 85%;
        margin-left: -15px;

        .burger {
          margin-left: 0;
        }

        .navbar-brand {
          width: 194px;
          margin: 0;
        }

        .page-title-wrapper {
          margin-left: 15px;

          .back-to-section {
            &.hidden + .page-title {
              max-width: 340px;
            }

            + .page-title {
              font-family: DINPro-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
              font-size: 1.25em; // 20px
              max-width: 320px;
            }
          }
        }
      }

      .navbar-nav {
        right: 0;
        top: 0;

        > li {
          + li {
            margin-left: 20px;
          }

          &:first-child {
            margin-top: 15px;

            i {
              font-size: 2em;
            }
          }

          > a {
            &#btn-open-share-overlay {
              background-position: -226px top;
              @include size(35px, 34px);
              margin-top: 13px;

              &:hover, &:focus {
                background-position: -226px -34px;
              }
            }
          }

          &.nav-sections {
            margin: 0 13px;

            a {
              font-size: 28px;
              line-height: 1;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    #top-bar .navbar-header .page-title-wrapper {
      margin-left: 20px;

      .back-to-section {
        &.hidden + .page-title {
          max-width: 100%;
        }

        + .page-title {
          font-size: 1.75em; // 28px
          vertical-align: -15%;
          max-width: 100%;
        }
      }
    }
    &.subSection, &.espace {
      #top-bar .navbar-header .page-title-wrapper {
        margin-left: 70px;

        .back-to-section + .page-title {
          margin-left: 50px;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    &.subSection, &.espace {
      #top-bar .navbar-header .page-title-wrapper {
        margin-left: 315px;
      }
    }
  }
}

/*********************
06. NAVIGATION STYLES
*********************/

/* Main menu */
#menu-menu-principal, #menu-menu-principal-anglais {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;

  > li {
    > a {
      font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 1.125em; // 18px
      line-height: 1.5;
    }

    + li {
      margin-top: 20px;
    }

    &.active > a {
      color: $brand-primary;
    }

    &.menu-item-has-children {
      > a {
        position: relative;

        &:before {
          content: "+";
          font-family: DINPro-Black, "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 1.25em; // 20px
          position: absolute;
          left: -20px;
          top: -5px;
        }
      }

      .sub-menu {
        display: none;
        list-style-type: none;
        font-family: $font-family-sans-serif;
        padding: 0;

        > li {
          > a {
            display: inline-block;
            line-height: 1.25;
          }

          &.active > a {
            color: $brand-primary;
          }
        }
      }

      &.active {
        > a:before {
          content: "-";
        }

        .sub-menu {
          display: block;
        }
      }
    }
  }
}

/* Secondary & tertiary menu */
#menu-menu-secondaire, #menu-menu-secondaire-anglais, #menu-menu-tertiaire, #menu-menu-tertiaire-anglais {
  list-style-type: none;
  font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-top: 1px solid $brand-primary;
  padding: 20px 0 0;
  margin-bottom: 25px;

  li + li {
    margin-top: 5px;
  }
}

#social {
  margin: 20px 0 10px;

  a {
    color: $brand-primary;

    &:hover {
      color: #000;
    }

    .fa-stack {
      width: 1.9em;
      height: 1.9em;
      line-height: 1.9em;
    }
  }
}

address {
  font-size: .75em; // 12px
  margin-bottom: 20px;

  .acces {
    display: block;
    font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 5px;

    &:before {
      content: "";
      display: inline-block;
      background-position: -30px top;
      background-repeat: no-repeat;
      @include retinize('sprite', 'png', 286px, 84px);
      @include size(16px, 22px);
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
  }
}

#bottom-links {
  font-size: .75em; // 12px
  margin-bottom: 60px;

  span {
    display: block;

    a {
      color: #000;
      text-decoration: none;

      &:hover, &:focus {
        color: $brand-primary;
      }
    }
  }
}

/* Menu latéral */
body {
  @media only screen and (min-width: $screen-sm-min) {
    #anchors {
      position: fixed;
      right: 0;
      top: 50%;
      z-index: 1000;
      text-align: right;

      .anchors-toggle {
        position: relative;
        right: 10px;
        margin-top: -51px;

        .icon-wrap {
          margin: 0 3px 60px 0;

          .icon-bar {
            display: block;
            background-color: $brand-primary;
            @include size(4px, 32px);

            + .icon-bar {
              margin-top: 3px;
            }
          }
        }
      }

      .anchors-nav {
        position: relative;
        height: auto !important;
        margin-top: -99px;

        .sections-menu {
          font-family: DINPro-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
          @include uppercase();
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            + li {
              margin-top: 7px;
            }

            a {
              background-color: rgba(255, 255, 255, .9);
              color: $brand-primary;
              @include transition(all .6s ease);
              padding: 6px 12px;
            }

            &.active a, &.hover a {
              background-color: $brand-primary;
              color: #fff;
            }
          }
        }
      }
    }
    &[data-page-id="2"], &[data-page-id="6"] {
      #anchors .anchors-nav .sections-menu li {
        a {
          background-color: rgba($brand-primary, 0);
          color: #fff;
        }

        &.active a, &.hover a {
          background-color: rgba($brand-primary, 1);
        }

        &.active-prev-1 a, &.active-next-1 a, &.hover-prev-1 a, &.hover-next-1 a {
          background-color: rgba($brand-primary, .5);
        }

        &.active-prev-2 a, &.active-next-2 a, &.hover-prev-2 a, &.hover-next-2 a {
          background-color: rgba($brand-primary, .3);
        }
      }
    }
  }
}

/*********************
07. CONTENT STYLES
*********************/

/*** CSS partagé **/
/* Buttons */
.btn-close-news, .close-sub-section, .btn-close {
  &:before {
    content: "";
    background-color: $brand-primary;
    @include size(30px, 30px);
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    content: "";
    background-position: left -61px;
    background-repeat: no-repeat;
    @include retinize('sprite', 'png', 286px, 84px);
    @include size(24px, 23px);
    position: absolute;
    right: 3px;
    top: 4px;
  }

  &:hover, &:focus {
    &:before {
      background: none;
      border: 1px solid $brand-primary;
    }

    &:after {
      background-position: -24px -61px;
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    &:before {
      right: 7px;
    }
    &:after {
      right: 10px;
    }
  }
  @media only screen and (min-width: $screen-md-min) {
    &:before {
      @include size(60px, 60px);
      right: 0;
    }
    &:after {
      background-position: -94px -38px;
      @include size(41px, 39px);
      right: 9px;
      top: 11px;
    }
    &:hover:after, &:focus:after {
      background-position: -135px -38px;
    }
  }
}

.close-sub-section:hover, .close-sub-section:focus {
  &:before {
    border-color: #fff;
  }

  &:after {
    background-position: left -61px;
  }

  @media only screen and (min-width: $screen-md-min) {
    &:before {
      border-color: $brand-primary;
    }
    &:after {
      background-position: -135px -38px;
    }
  }
}

.prev-sub-section, .next-sub-section, .prev-espace, .next-espace {
  &:before {
    content: "";
    border: 1px solid #fff;
    @include size(30px, 30px);
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    content: "";
    background-position: -46px -41px;
    background-repeat: no-repeat;
    @include retinize('sprite', 'png', 286px, 84px);
    @include size(12px, 20px);
    position: absolute;
    right: 8px;
    top: 5px;
  }

  &:hover:before, &:focus:before {
    background-color: $brand-primary;
    border: none;
  }

  @media only screen and (min-width: $screen-sm-min) {
    right: 7px;
  }
  @media only screen and (min-width: $screen-md-min) {
    right: 0;
    &:before {
      border-color: $brand-primary;
      @include size(60px, 60px);
    }
    &:after {
      background-position: -70px top;
      @include size(24px, 41px);
      right: 18px;
      top: 10px;
    }
    &:hover:after, &:focus:after {
      background-position: -46px top;
    }
  }
}

.prev-sub-section, .prev-espace {
  &:after {
    @include rotate(180deg);
    right: 10px;
  }

  @media only screen and (min-width: $screen-md-min) {
    &:after {
      right: 20px;
    }
    &:hover:after, &:focus:after {
      background-position: -46px top;
    }
  }
}

/* Background slider */
.full-background-slider {
  overflow: hidden;
  position: relative;
  @include size(100%, 160px);

  .slide {
    display: none;
    position: absolute;
    z-index: 0;
    @include size(100%, 100%);
    background-size: cover;
    background-position: center center;
    $KenBurnsDuration: 14s;
    @keyframes move {
      from {
        @include scale(1.1);
      }
      to {
        @include scale(1);
      }
    }

    &.KenBurns {
      @include animation(move $KenBurnsDuration ease alternate);

      &.KenBurns-origin-1 {
        @include transform-origin(10% 40%);
      }

      &.KenBurns-origin-2 {
        @include transform-origin(90% 60%);
      }
    }

    &.active {
      display: block;
      z-index: 10;
    }
  }

  &.paused .slide.KenBurns {
    animation-play-state: paused;
  }

  @media only screen and (min-width: $screen-sm-min) {
    height: 210px;
  }

  @media only screen and (min-width: $screen-md-min) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}

.full-background-single {
  overflow: hidden;
  position: relative;
  @include size(100%, 160px);
  background-size: cover;
  background-position: center center;

  @media only screen and (min-width: $screen-sm-min) {
    height: 210px;
  }

  @media only screen and (min-width: $screen-md-min) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}

/* --------------------------------------------- */

/*** Sections ***/
.section {
  overflow: hidden;

  .section-content {
    position: relative;
    height: 100%;

    .scroll-wrap {
      margin-top: 20px;
    }

    .text-intro {
      font-family: DINPro-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .gros-titre {
      font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 3.375em; // 54px
      color: rgba(255, 255, 255, .9);
      position: absolute;
      left: 50%;
      top: 30px;
      z-index: 100;
      text-align: center;
      width: 320px;
      margin-left: -160px;
    }

    .bloc {
      margin-top: 20px;

      a {
        h2 {
          position: relative;
          font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 1.25em; // 20px
          padding-right: 35px;
          margin: 5px 0;

          &:before {
            content: "";
            background-color: $brand-primary;
            @include size(30px, 30px);
            position: absolute;
            right: 0;
            top: 7px;
          }

          &:after {
            content: "";
            background-position: -46px -41px;
            background-repeat: no-repeat;
            @include retinize('sprite', 'png', 286px, 84px);
            @include size(12px, 20px);
            position: absolute;
            right: 8px;
            top: 12px;
          }
        }

        p {
          line-height: $headings-line-height;
          color: $text-bloc;
          padding-right: 35px;
        }
      }
    }

    .toggle-intro-detail {
      position: relative;
      left: 0;
      @include transition(left .6s ease);

      .intro {
        position: relative;
        padding: 15px;

        h2 {
          font-size: 1.125em; // 18px
          line-height: $line-height-base;
          color: $brand-primary;
          margin: 0;
        }

        p {
          margin-top: 40px;
          /*&:before {
            content: "";
            display: block;
            background-color: $brand-primary;
            @include size(60px, 10px);
            position: relative;
            top: -10px;
          }*/
        }

        span.separator {
          @include separator();
        }

        .more {
          display: block;
          color: $brand-primary;
          text-align: center;
          position: relative;
          margin-top: 80px;

          &:before {
            content: "";
            background-color: $brand-primary;
            @include size(60px, 60px);
            position: absolute;
            left: 50%;
            top: -70px;
            margin-left: -30px;
          }

          &:after {
            content: "";
            background-position: -46px top;
            background-repeat: no-repeat;
            @include retinize('sprite', 'png', 286px, 84px);
            @include size(24px, 41px);
            position: absolute;
            left: 50%;
            top: -60px;
            margin-left: -10px;
          }

          &:hover, &:focus {
            &:before {
              background: none;
              border: 1px solid $brand-primary;
            }

            &:after {
              background-position: -70px top;
            }
          }
        }
      }

      .detail {
        display: none;
        @include size(100%, 100%);
        position: relative;
        left: 100%;
        z-index: 1000;
        padding: 15px;

        .scroll-wrap {
          h3 {
            font-size: 1em; // 16px
            margin: 45px 0 5px;
            /*&:before {
              display: block;
              content: "";
              background: $brand-primary;
              @include size(60px, 10px);
              position: relative;
              top: -15px;
            }*/
          }
        }

        .btn-close {
          position: absolute;
          right: 15px;
          top: 50px;
        }
      }

      &.toggle {
        left: -100%;

        .intro {
          display: none;
        }

        .detail {
          display: block;
        }
      }
    }
  }

  .loader {
    left: 50%;
    top: 50%;
    @include size(160px, 70px);
    margin: -35px 0 0 -80px;
  }

  &.page-principale .section-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    h2 {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 250px;
      margin: -166px 0 0 -105px;
    }

    .next-section {
      background-position: -94px top;
      background-repeat: no-repeat;
      @include retinize('sprite', 'png', 286px, 84px);
      @include size(66px, 38px);
      position: absolute;
      left: 50%;
      bottom: 45px;
      margin-left: -33px;
      @keyframes bounce {
        0% {
          @include translate(0, 0);
          @include opacity(1);
        }
        50% {
          @include translate(0, -25%);
          @include opacity(.5);
        }
        100% {
          @include translate(0, 0);
          @include opacity(1);
        }
      }
      @include animation(bounce .95s ease-in-out infinite);

      &:hover {
        @include animation(none);
      }
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    .section-content {
      .scroll-wrap {
        margin-top: 30px;
      }

      .text-intro {
        font-size: 1.125em; // 18px
      }

      .bloc {
        margin-top: 30px;

        a {
          h2 {
            padding: 0;

            &:before {
              right: 10px;
              top: -45px;
            }

            &:after {
              right: 18px;
              top: -40px;
            }
          }

          p {
            padding: 0;
          }

          &:hover h2:before, &:focus h2:before {
            background: none;
            border: 1px solid #fff;
          }
        }
      }

      .gros-titre {
        font-size: 5em; // 80px
        top: 30px;
        width: 450px;
        margin-left: -225px;
      }

      .img-mot-cle {
        background-position: left top;
        background-repeat: no-repeat;

        &.renforcer {
          @include retinize('renforcer', 'png', 149px, 121px);
          @include size(149px, 121px);
          margin: 5em auto 0;
        }

        &.reinforce {
          @include retinize('reinforce', 'png', 145px, 120px);
          @include size(145px, 120px);
          margin: 5em auto 0;
        }
      }

      .toggle-intro-detail {
        .intro {
          position: absolute;
          padding: 30px;

          h2 {
            font-size: 1.75em; // 28px
            line-height: $headings-line-height;
            margin-bottom: 50px;
          }

          .more {
            position: absolute;
            left: 50%;;
            bottom: 160px;
            margin-left: -50px;
          }
        }

        .detail .scroll-wrap .mCSB_scrollTools {
          right: 14px;
          top: 40px;
        }
      }
    }
    &.page-principale .section-content {
      h2 {
        width: auto;
        left: 50%;
        margin: -225px 0 0 -344px;
      }

      .next-section {
        bottom: 60px;
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    .section-content {
      .gros-titre {
        font-size: 4em; // 64px
        width: 50%;
        top: 50%;
        margin: -72px 0 0 0;
        @include transition(all .6s ease);
      }

      .img-mot-cle {
        position: absolute;
        left: 75%;
        bottom: 15%;
        z-index: 100;
        @include size(143px, 120px);
        @include transition(all .6s ease);
        margin-left: -71px;

        &.renforcer, &.reinforce {
          position: static;
          margin: 3em auto 0;
        }

        &.reunir {
          @include retinize('reunir', 'png', 143px, 120px);
        }

        &.reunite {
          @include retinize('reunite', 'png', 143px, 120px);
        }

        &.recevoir {
          @include retinize('recevoir', 'png', 143px, 120px);
          left: 25%;
        }

        &.receive {
          @include retinize('receive', 'png', 143px, 120px);
          left: 25%;
        }

        &.rassembler {
          @include retinize('rassembler', 'png', 153px, 120px);
          @include size(153px, 120px);
          margin-left: -76px;
        }

        &.round-up {
          @include retinize('round-up', 'png', 146px, 120px);
          @include size(153px, 120px);
          margin-left: -76px;
        }
      }

      .toggle-intro-detail {
        .intro {
          z-index: 1000;
          height: 100%;
          width: 50%;
          background-color: rgba(255, 255, 255, .9);

          .more {
            left: auto;
            right: 110px;
            bottom: 80px;

            &:before {
              left: auto;
              right: -80px;
              top: -15px;
            }

            &:after {
              left: auto;
              right: -62px;
              top: -5px;
            }
          }
        }

        .detail {
          display: block;
          background-color: rgba(255, 255, 255, .9);
          width: 65%;
          height: 100%;
          padding: 0;

          .anchors {
            float: left;
            list-style-type: none;
            font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 1.125em; // 18px
            line-height: $headings-line-height;
            width: 20%;
            padding: 25px;
            margin: 0;

            li {
              margin-top: 40px;

              &:before {
                display: block;
                content: "";
                background: $brand-primary;
                @include size(60px, 2px);
                position: relative;
                left: 0;
                top: -20px;
              }

              &:first-child {
                margin-top: 30px;

                &:before {
                  content: none;
                }
              }

              a {
                color: $brand-primary;

                &:hover, &:focus {
                  color: #000;
                }
              }
            }
          }

          .scroll-wrap {
            background-color: #fff;
            height: 100% !important;
            padding: 30px 85px 30px 30px;
            margin-left: 20%;
            margin-top: 0;

            .mCSB_scrollTools {
              right: 0;
              top: 30px;
            }
          }

          .btn-close {
            top: auto;
            bottom: 90px;
          }
        }

        &.toggle {
          left: -50%;

          .detail {
            left: 85%;
          }

          + .gros-titre {
            left: 0;
            width: 35%;

            + .img-mot-cle {
              left: 17.5%;
            }
          }
        }

        &.align-right {
          .intro {
            left: 50%;
          }

          .detail {
            left: 135%;
          }

          + .gros-titre {
            left: 0;
          }

          &.toggle {
            left: -100%;
          }
        }
      }
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    .section-content {
      .text-intro {
        max-width: 1200px;
      }

      .bloc a {
        h2 {
          font-size: 1.5em; // 24px
          margin-top: 10px;

          &:before {
            @include size(60px, 60px);
            right: 15px;
            top: -85px;
          }

          &:after {
            background-position: -46px top;
            @include size(24px, 41px);
            right: 33px;
            top: -75px;
          }
        }
      }

      .gros-titre {
        font-size: 5.625em; // 90px
        margin-top: -90px;
      }

      .img-mot-cle.renforcer, .img-mot-cle.reinforce {
        margin-top: 2em;
      }

      .toggle-intro-detail {
        .detail {
          width: 60%;
        }

        &.toggle {
          .detail {
            left: 90%;
          }

          + .gros-titre {
            width: 40%;

            + .img-mot-cle {
              left: 20%;
            }
          }
        }

        &.align-right .detail {
          left: 140%;
        }
      }
    }
  }
}

/* --------------------------------------------- */

/*** Sub-sections ***/
.sub-section {
  position: fixed;
  z-index: 20;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;

  .full-background-single {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    @include size(100%, 160px);
  }

  .content-wrapper {
    padding: 15px;

    h2 {
      font-size: 1.125em; // 18px
      line-height: $line-height-base;
      color: $brand-primary;
      margin: 0;
    }

    p, ul {
      padding: 0;
      margin: 40px 0 0;
      /*&:before {
        content: "";
        display: block;
        background-color: $brand-primary;
        @include size(60px, 10px);
        position: relative;
        top: -10px;
      }*/
    }

    span.separator {
      @include separator();
    }
  }

  .controls {
    position: absolute;
    right: 10px;
    top: -20px;

    a {
      position: relative;
      display: block;
      margin: 40px 0;
    }
  }

  .loader {
    left: 50%;
    top: 50%;
    @include size(160px, 70px);
    margin: -35px 0 0 -80px;
  }

  &.les-espaces, &.spaces {
    .filter {
      font-family: DINPro-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: .875em; // 14px
      @include uppercase();
      margin: 15px 0;

      > span {
        color: $brand-primary;
      }

      #filter-espaces {
        font-size: 0;
        padding: 0;
        margin: 0 40px 0 0;

        li {
          list-style-type: none;
          display: inline-block;
          font-size: 14px;

          + li {
            margin-left: 10px;

            &:before {
              content: "|";
              color: $brand-primary;
              position: relative;
              left: -5px;
            }
          }

          &.active a {
            font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
            color: #000;
          }
        }
      }
    }

    #grid-espaces, #grid-espaces-filtered {
      .grid-scroll-wrap {
        padding-bottom: 40px;

        .grid-row {
          margin: 0;

          .grid-col {
            padding: 0;

            .grid-items {
              margin: 0;

              .grid-item {
                padding: 0;
                margin-bottom: 5px;

                div {
                  height: 100%;
                  padding: 0;
                  margin: 0;

                  .grid-img {
                    .grid-item-bg {
                      width: 100%;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: 50%;
                      background-color: #fff;
                    }

                    .grid-item-title {
                      display: none;
                    }
                  }

                  .grid-item-title {
                    font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
                    line-height: $headings-line-height;
                    height: auto;
                    padding-left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }

    #grid-espaces-filtered .grid-scroll-wrap .grid-row .grid-col .grid-items .grid-item.hidden-filtered {
      display: none !important;
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    position: absolute;
    .full-background-single {
      height: 290px;
    }
    .content-wrapper {
      padding: 30px;

      h2 {
        font-size: 1.75em; // 28px
        line-height: $headings-line-height;
        margin-bottom: 10px;
      }

      .content {
        @include content-columns(2, 5em);

        p, ul {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    &.hotellerie, &.restauration, &.services, &.conferences, &.concerts, &.lodgings, &.restaurant {
      .content-wrapper .content {
        @include content-columns(1, 0);
      }
    }
    &.les-espaces, &.spaces {
      .filter #filter-espaces {
        display: inline-block;
        vertical-align: top;
        margin-left: 5px;
      }

      #grid-espaces, #grid-espaces-filtered {
        .grid-scroll-wrap .grid-row .grid-col .grid-items .grid-item {
          margin: 0;

          a {
            &:hover div .grid-img {
              .grid-item-bg {
                @include opacity(.2);
              }

              .grid-item-title {
                font-family: DINPro-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
                @include uppercase();
                color: #000;
                display: block;
                position: absolute;
                left: 10px;
                bottom: 10px;
                width: 60%;
                padding: 0;
              }

              .more {
                position: absolute;
                right: 10px;
                bottom: 17px;
                height: 30px;

                &:before {
                  content: "";
                  background-color: $brand-primary;
                  @include size(30px, 30px);
                  position: absolute;
                  right: 0;
                  top: 7px;
                }

                &:after {
                  content: "";
                  background-position: -46px -41px;
                  background-repeat: no-repeat;
                  @include retinize('sprite', 'png', 286px, 84px);
                  @include size(12px, 20px);
                  position: absolute;
                  right: 8px;
                  top: 12px;
                }
              }
            }
          }
        }
      }

      .controls {
        top: 10px;
      }
    }
  }

  @media only screen and (max-width: $screen-sm-max) {
    &.les-espaces .controls, &.spaces .controls {
      .next-sub-section {
        &:before {
          border-color: $brand-primary;
        }

        &:after {
          background-position: -58px -41px;
        }

        &:hover:after, &:focus:after {
          background-position: -46px -41px;
        }
      }

      .close-sub-section:hover {
        &:before {
          background: none;
          border-color: $brand-primary;
        }

        &:after {
          background-position: -24px -61px;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    .section-content {
      height: 100%;

      .full-background-single {
        background-size: cover;
        height: 100%;
        position: absolute;
      }

      > .row {
        height: 100%;

        > .col-xs-12 {
          height: 100%;

          .content-wrapper {
            background-color: rgba(255, 255, 255, .9);
            height: 100%;
            padding: 30px;
          }
        }
      }

      .controls {
        right: 15px;
        top: auto;
        bottom: 0;

        a {
          margin: 80px 0;
        }
      }

      &.layout-text-left {
        .close-sub-section:hover, .close-sub-section:focus {
          &:before {
            border-color: #fff;
          }

          &:after {
            background-position: -94px -38px;
          }
        }

        .prev-sub-section, .next-sub-section {
          &:before {
            border-color: #fff;
          }

          &:after {
            background-position: -46px top;
          }
        }
      }

      &.layout-text-right > .row > .col-xs-12 .content-wrapper .scroll-wrap {
        margin-right: 60px;
      }
    }
    &.experience-royaumont .section-content .content-wrapper .content, &.the-royaumont-experience .section-content .content-wrapper .content {
      @include content-columns(1, 0);
    }
    &.les-espaces .section-content, &.spaces .section-content {
      .filter {
        font-size: 1.125em; // 18px
        margin: 25px 0;

        #filter-espaces li {
          font-size: 18px;

          + li {
            margin-left: 20px;

            &:before {
              left: -10px;
            }
          }
        }
      }

      #grid-espaces, #grid-espaces-filtered {
        .grid-scroll-wrap .grid-row .grid-col .grid-items .grid-item a:hover div .grid-img .grid-item-title {
          font-size: 1.25em; // 20px
        }
      }
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    &.les-espaces .section-content, &.spaces .section-content {
      #grid-espaces, #grid-espaces-filtered {
        .grid-scroll-wrap .grid-row .grid-col .grid-items .grid-item {
          a:hover div .grid-img {
            .grid-item-title {
              font-size: 1.75em; // 28px
            }

            .more {
              right: -5px;
              bottom: -45px;

              &:before {
                @include size(60px, 60px);
                right: 15px;
                top: -85px;
              }

              &:after {
                background-position: -46px top;
                @include size(24px, 41px);
                right: 33px;
                top: -75px;
              }
            }
          }
        }
      }
    }
  }
}

/* --------------------------------------------- */

/*** Liste Actualites ***/
#news-pane {
  font-family: $actus-font-family;
  background-color: #fff;
  position: fixed;
  z-index: 300;
  top: 60px;
  width: 100%;
  left: -100%;

  .list-wrap {
    position: relative;
    float: left;
    width: 100%;
    padding: 30px 15px;

    .mCSB_container {
      margin-right: 40px;

      .entry {
        cursor: pointer;

        .featured {
          width: 60px;
        }

        .featured {
          margin-bottom: 5px;
        }

        .date {
          display: block;
          font-family: $font-family-sans-serif;
          font-size: .75em; // 12px
          line-height: $headings-line-height;
          color: $brand-primary;
        }

        h2 {
          font-family: Akkurat-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 1.125em; // 18px
          margin: 0 0 5px;
        }

        + .entry {
          border-top: 1px solid $brand-primary;
          padding-top: 30px;
          margin-top: 30px;
        }

        &:hover h2 {
          color: $brand-primary;
        }
      }
    }

    .mCSB_scrollTools {
      right: 7px;
      top: 40px;
    }

    .loader {
      left: 50%;
      bottom: 40px;
      width: 280px;
      margin-left: -140px;
    }

    .no-more {
      position: relative;
      bottom: 30px;
      font-family: $font-family-sans-serif;
      font-size: .75em;
      color: $brand-primary;
      text-align: center;
    }
  }

  .btn-close-news {
    position: absolute;
    right: 15px;
    top: 30px;
  }

  @media only screen and (min-width: $screen-sm-min) {
    box-shadow: 2px 0 10px rgba(0, 0, 0, .15);
    width: 45%;
    left: -45%;
    &.no-shadow {
      box-shadow: none;

      .entry {
        @include opacity(.2);

        &.active {
          @include opacity(1);
        }
      }
    }
    .list-wrap .mCSB_container {
      .loader {
        bottom: 5px;
      }

      .no-more {
        bottom: 0;
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    .list-wrap {
      .mCSB_container {
        margin-right: 80px;

        .entry {
          .featured {
            width: 100px;
          }

          h2 {
            font-size: 1.75em; // 28px
          }

          .content {
            color: $text-actu;
          }
        }

        .no-more {
          font-size: .875em; // 14px
        }
      }

      .mCSB_scrollTools {
        right: 21px;
        top: 80px;
      }
    }
  }
}

/* --------------------------------------------- */

/*** Actualite ***/
#news-detail {
  font-family: $actus-font-family;
  background-color: #fff;
  position: fixed;
  z-index: 350;
  top: 60px;
  width: 100%;
  left: 0;

  .entry {
    padding: 30px 15px;

    .mCSB_container {
      margin-right: 40px;

      .featured {
        margin-bottom: 15px;
      }

      h1 {
        font-family: Akkurat-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.125em; // 18px
        color: $brand-primary;
        margin-top: 5px;
      }
    }

    .mCSB_scrollTools {
      right: 7px;
      top: 40px;
    }
  }

  .loader {
    left: 50%;
    bottom: 50%;
    width: 300px;
    margin: -30px 0 0 -150px;
  }

  .btn-close-news {
    position: absolute;
    right: 15px;
    top: 30px;
  }

  @media only screen and (min-width: $screen-sm-min) {
    box-shadow: 2px 0 10px rgba(0, 0, 0, .15);
    width: 45%;
    z-index: 280;
  }

  @media only screen and (min-width: $screen-md-min) {
    .entry {
      .mCSB_container {
        margin-right: 80px;

        h1 {
          font-size: 1.75em; // 28px
        }
      }

      .mCSB_scrollTools {
        right: 21px;
        top: 80px;
      }
    }
  }
}

/* --------------------------------------------- */

.gform_wrapper {
  .gfield_single-checkbox {
    .gfield_label {
      display: none;
    }

    .gfield_checkbox {
      li {
        display: flex;
      }
    }

    input[type=checkbox] {
      display: inline-block;
      width: auto !important;
      height: auto;
      margin-top: 5px;
      margin-right: 10px;
    }

    &.gfield_error {
      label {
        color: #a94442;

        a {
          color: #a94442;
        }
      }
    }

    a {
      text-decoration: underline;
    }
  }
}

/*** Contact ***/
#contact-pane {
  font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  left: 0;
  z-index: 10000;

  .pane-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border: 1px solid $brand-primary;
    border-bottom: 0;
    font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    @include uppercase();
    @include size(170px, 40px);
    padding-top: 6px;
    margin: 0 auto -1px;

    a:focus {
      color: #000;

      &:hover {
        color: $brand-primary;
      }
    }
  }

  .pane-body {
    background-color: #fff;
    border: 1px solid $brand-primary;
    border-bottom: 0;
    padding: 15px;

    .intro {
      margin-bottom: 25px;
    }

    .gform_wrapper {
      .gform_body ul li {
        margin: 0;
      }

      .gform_footer {
        font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: $text-bloc;

        input[type="submit"] {
          margin-right: 9px;
        }

        > a {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          line-height: $headings-line-height;
          width: 140px;
          background-color: $text-bloc;
          color: #e4e1db;
          padding: 4px;
          margin-left: 9px;

          &:before {
            content: "> ";
          }

          &:hover, &:focus {
            background-color: #000;
          }
        }
      }
    }

    .gform_confirmation_message {
      text-align: left;
    }

    .footer-infos {
      font-size: .8125em; // 13px
      line-height: $headings-line-height;
      text-align: center;
      padding-bottom: 5px;
      margin-top: 10px;

      .cartouche {
        display: inline-block;
        border: solid 1px #a59c89;
        padding: 0px 7px 6px;

        address {
          font-style: normal;
          font-size: inherit;
          line-height: inherit;
          margin-bottom: 0;
        }
      }

      span {
        display: block;
        margin-top: 5px;

        &.tel {
          color: #000 !important;
        }
      }
    }

    .mCSB_scrollTools {
      right: 7px;
    }
  }

  .btn-close {
    position: absolute;
    right: 16px;
    top: 55px;
  }

  @media only screen and (max-width: $screen-xs-max) {
    &.opening, &.open {
      .pane-header {
        text-align: left;
        width: 100%;
        padding: 15px 0 0 15px;
      }

      .btn-close {
        top: 15px;
        z-index: 1;
      }
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    left: 50%;
    width: 700px;
    margin-left: -350px;
    .pane-header {
      width: 200px;
    }
    .pane-body {
      padding: 15px 30px;

      .intro {
        font-size: 1.75em; // 28px
        text-align: center;
      }

      .gform_wrapper {
        position: relative;

        input {
          width: 30%;
        }

        #field_1_7, #field_1_8, #field_3_7, #field_3_8 {
          width: 65%;
          margin-left: 5%;
          position: absolute;
          right: 0;
          top: 0;

          textarea {
            height: 198px;
          }
        }

        #field_1_8, #field_3_8 {
          margin: 0;
          bottom: 70px;
          top: auto;
          font-size: .75em;
          color: #746e60;
        }

        .gform_footer {
          float: right;
          width: 65%;
          text-align: right;
          margin-top: 15px;

          input[type=submit] {
            font-size: 16px;
            width: auto;
          }

          > a {
            font-size: 1em; // 16px
            width: auto;
            padding-bottom: 5px;
          }
        }
      }

      .gform_confirmation_message {
        text-align: left;
      }

      .footer-infos {
        font-size: .875em; // 14px
        margin-top: 10px;

        span {
          &:first-child {
            display: inline-block;

            &:after {
              content: " | ";
            }
          }

          &.tel {
            display: inline-block;
          }
        }
      }
    }
  }
}

/* --------------------------------------------- */

/*** Devis ***/
#quote-pane {
  font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 60px;
  left: 0;
  z-index: 10000;
  padding: 15px 0;

  .container {
    position: relative;

    .pane-header {
      font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
      @include uppercase();
      margin-bottom: 25px;
    }

    .pane-body {
      .gform_wrapper {
        .gform_body > ul {
          padding: 0 15px;
          margin: 0;

          li {
            margin: 0;

            &.gfield_html {
              font-size: .75em; // 12px
              color: $text-bloc;
              margin-top: 10px;
            }
          }

          &:first-child {
            background-color: $brand-primary;
            color: #fff;
            padding: 10px 15px;
            margin-bottom: 25px;

            input, textarea {
              background-color: $brand-primary;
              border-color: rgba(255, 255, 255, .3);
              color: #fff;
            }

            textarea {
              height: 75px;
            }

            .gfield_required {
              color: #fff;
            }
          }
        }

        .gform_footer {
          margin: 20px 0 0 15px;
        }
      }

      .mCSB_scrollTools {
        right: 7px;
      }
    }

    .btn-close {
      position: absolute;
      right: 15px;
      top: 0;
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    padding: 30px 0;
    .container {
      .pane-header {
        font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.75em; // 28px
        text-transform: none;
        margin-bottom: 50px;
      }

      .pane-body .gform_wrapper {
        .gform_body {
          width: 100%;

          > ul {
            display: inline-block;
            vertical-align: top;
            width: 25%;
            padding: 10px 15px;

            .empty {
              margin: 0;
            }

            + ul {
              margin-left: 2%;

              + ul {
                width: 45%;
                margin-left: 3%;

                textarea {
                  height: 312px;
                }
              }
            }
          }
        }

        .gform_footer {
          position: relative;
          z-index: 1;
          float: right;
          margin-top: -35px;
        }
      }

      .btn-close {
        right: 8px;
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    .container {
      .pane-body .gform_wrapper {
        label {
          font-size: 1em; // 16px
        }

        .gform_body > ul {
          li.gfield_html {
            font-size: .875em; // 18px
          }

          + ul + ul textarea {
            height: 271px;
          }
        }

        .gform_footer {
          margin-top: -55px;
        }
      }

      .btn-close {
        right: 15px;
      }
    }
  }
}

/* --------------------------------------------- */

/*** Newsletter, Visite 360, Page standard ***/
#newsletter, #visite-360, #std-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  z-index: 10000;
  padding: 15px 0;

  .container {
    position: relative;

    .pane-header {
      font-family: DINPro-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
      @include uppercase();
      margin-bottom: 15px;
    }

    .pane-body {
      .tabs {
        color: $brand-primary;
        line-height: $headings-line-height;
        margin-bottom: 15px;

        a.active {
          color: $brand-primary;
        }
      }

      .tab-content, .tab-pane {
        height: 100%;
      }

      iframe {
        display: block;
        margin: 0 auto;
        max-width: 600px;
      }
    }

    .btn-close {
      position: absolute;
      right: 15px;
      top: 0;
    }
  }

  @media only screen and (min-width: $screen-sm-min) {
    padding: 30px 0;
    .container {
      .pane-header {
        font-family: DINPro-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.75em; // 28px
        text-transform: none;
        margin-bottom: 30px;
      }

      .pane-body iframe {
        max-width: 800px;
      }

      .btn-close {
        right: 8px;
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    .container .btn-close {
      right: 15px;
    }
  }
}

/* --------------------------------------------- */

/*** Espaces ***/
#espace-detail {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  z-index: 275;
  padding: 10px 0;

  .entry {
    position: relative;

    .title {
      font-family: DINPro-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: .875em; // 14px
      line-height: $headings-line-height;
      @include uppercase();
      margin-bottom: 20px;
    }

    .media-viewer-switch {
      margin-bottom: 5px;

      a {
        img {
          display: block;
        }

        &.active {
          color: $brand-primary;
        }

        + a {
          margin-left: 10px;

          &:before {
            content: "|";
            color: $brand-primary;
            position: relative;
            left: -7px;
          }
        }
      }
    }

    .media-viewer {
      max-width: 1024px;

      > div {
        position: relative;

        img {
          cursor: pointer;
        }

        .caption {
          display: block;
          text-align: center;
          font-size: .875em; // 14px
          line-height: $headings-line-height;
          height: 20px;
          padding: 0 20px;
          margin-top: 6px;
        }

        .controls a {
          font-size: 0;
          position: absolute;
          left: 15px;
          top: 50%;
          margin-top: -12px;

          &:before {
            display: block;
            content: "";
            background-position: -176px -38px;
            background-repeat: no-repeat;
            @include retinize('sprite', 'png', 286px, 84px);
            @include size(14px, 24px);
          }

          &.btn-next {
            left: auto;
            right: 15px;

            &:before {
              @include rotate(180deg);
            }
          }
        }

        &.v360, &.video {
          p {
            margin: 0;

            iframe {
              display: block;
            }
          }

          .controls a {
            top: auto;
            bottom: -5px;
            left: 0;
            margin: 0;

            &:before {
              background-position: -204px -38px;
            }

            &.btn-next {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }

    .infos {
      margin-top: 15px;

      .mCSB_container {
        padding-bottom: 40px;

        .btn-fiche-technique {
          display: inline-block;
          background-color: $brand-primary;
          border: 1px solid #fff;
          @include uppercase();
          line-height: $headings-line-height;
          color: #fff;
          max-width: 170px;
          padding: 5px 10px;
          margin-bottom: 30px;

          &:hover, &:focus {
            background: none;
            border-color: $brand-primary;
            color: $brand-primary;
          }
        }

        .btn-more {
          display: inline-block;
          border: 1px solid $brand-primary;
          @include uppercase();
          padding: 5px 10px;

          &:hover, &:focus {
            color: $brand-primary;
          }
        }
      }
    }

    > .controls {
      position: absolute;
      right: 0;
      top: -20px;

      a {
        position: relative;
        display: inline-block;
        margin-left: 35px;

        &.prev-espace, &.next-espace {
          &:before {
            border-color: $brand-primary;
          }

          &:after {
            background-position: -58px -41px;
          }

          &:hover, &:focus {
            &:before {
              background-color: $brand-primary;
              border: none;
            }

            &:after {
              background-position: -46px -41px;
            }
          }
        }
      }
    }
  }

  .loader {
    left: 50%;
    top: 50%;
    @include size(160px, 70px);
    margin: -35px 0 0 -80px;
  }

  @media only screen and (min-width: $screen-sm-min) {
    .entry {
      .title {
        font-size: 1em; // 16px
      }

      .media-viewer > div {
        .caption {
          padding: 0 130px;
        }

        .controls {
          margin-top: 6px;

          a {
            font-size: .875em; // 14px
            color: $brand-primary;
            left: 0;
            top: auto;
            padding-left: 20px;
            margin: 0;

            &:before {
              position: absolute;
              left: 0;
              background-position: -204px -38px;
            }

            &.btn-next {
              right: 0;
              padding: 0 20px 0 0;

              &:before {
                left: auto;
                right: 0;
              }
            }

            &:hover {
              color: #000;

              &:before {
                background-position: -190px -38px;
              }
            }
          }
        }

        &.photos .photo-wrap {
          overflow: hidden;
        }
      }

      .media-viewer-switch {
        margin: 0;

        a {
          position: relative;

          + a {
            display: block;
            margin: 6px 0 0;

            &:before {
              content: none;
            }
          }

          &.btn-video:before, &.btn-v360:before {
            content: "\f04b";
            font-family: fontAwesome;
            font-size: 34px;
            color: rgba(255, 255, 255, .8);
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -40px 0 0 -13px;
          }

          &.btn-v360:before {
            content: "\f047";
            margin-left: -17px;
          }

          &:hover:before {
            color: #fff;
          }
        }
      }

      .infos {
        margin: 0;
      }
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    padding: 20px 0;
    .entry {
      .title {
        font-size: 2.125em; // 34px
      }

      .media-viewer-switch a + a {
        margin-top: 13px;
      }

      > .controls a {
        &:before {
          @include size(30px, 30px);
        }

        &.btn-close {
          &:after {
            background-position: left -61px;
            @include size(24px, 23px);
            right: 3px;
            top: 4px;
          }

          &:hover:after, &:focus:after {
            background-position: -24px -61px;
          }
        }

        &.prev-espace:after, &.next-espace:after {
          @include size(12px, 20px);
          right: 8px;
          top: 5px;
        }

        &.prev-espace:after {
          right: 10px;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    .entry {
      .media-viewer-switch a {
        + a {
          margin-top: 23px;
        }

        &.btn-video:before, &.btn-v360:before {
          font-size: 52px;
          margin: -55px 0 0 -20px;
        }

        &.btn-v360:before {
          margin-left: -26px;
        }
      }

      > .controls a {
        margin-left: 75px;

        &:before {
          @include size(60px, 60px);
        }

        &.btn-close {
          &:after {
            background-position: -94px -38px;
            @include size(41px, 39px);
            right: 9px;
            top: 11px;
          }

          &:hover:after, &:focus:after {
            background-position: -135px -38px;
          }
        }

        &.prev-espace, &.next-espace {
          &:after {
            background-position: -70px top;
            @include size(24px, 41px);
            right: 18px;
            top: 10px;
          }

          &:hover:after, &:focus:after {
            background-position: -46px top;
          }
        }

        &.prev-espace:after {
          right: 20px;
        }
      }
    }
  }
}

/*********************
08. IE HACKS
*********************/

.alert-ie {
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  color: #000;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200000;
}
