/*
Hamburger Button
*/
// vars
$burgerColor: #fff; // icon color
$burgerWidth: 30px;
$burgerHeight: 22px;
$burgerScale: 1; // icon scale
$burgerTraitHeight: 2px;
$burgerSpeed: 0.3s; // animation speed
$burgerEase: ease-out;

.burger {
  transform: scale($burgerScale);
  position: relative;
  display: block;
  width: $burgerWidth;
  height: $burgerHeight;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  .burger-icon {
    position: absolute;
    width: $burgerWidth;
    height: $burgerHeight;
    .burger-container {
      position: relative;
      height: $burgerHeight;
      width: $burgerWidth;
      .burger-top,
      .burger-middle,
      .burger-bottom {
        position: absolute;
        display: block;
        height: $burgerTraitHeight;
        width: $burgerWidth;
        background: $burgerColor;
      }
      .burger-top {
        top: 0;
        transform-origin: ($burgerWidth - $burgerTraitHeight/2) $burgerTraitHeight/2;
      }
      .burger-bottom {
        bottom: 0;
        transform-origin: ($burgerWidth - $burgerTraitHeight/2) $burgerTraitHeight/2;
      }
      .burger-middle {
        top: ($burgerHeight - $burgerTraitHeight)/2;
      }
    }
  }
  &.is-open {
    .burger-top {
      animation: burger-top-out $burgerSpeed $burgerEase;
      animation-fill-mode: forwards;
    }
    .burger-bottom {
      animation: burger-bottom-out $burgerSpeed $burgerEase;
      animation-fill-mode: forwards;
    }
    .burger-middle {
      animation: burger-middle-out $burgerSpeed $burgerEase;
      animation-fill-mode: forwards;
    }
  }
  &.is-closed {
    .burger-top {
      animation: burger-top-in $burgerSpeed $burgerEase;
      animation-fill-mode: forwards;
    }
    .burger-bottom {
      animation: burger-bottom-in $burgerSpeed $burgerEase;
      animation-fill-mode: forwards;
    }
    .burger-middle {
      animation: burger-middle-in $burgerSpeed $burgerEase;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes burger-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
  100% {
    left: -(floor(($burgerWidth - cos(45deg)*$burgerWidth)/2));
    top: floor(($burgerHeight - sin(45deg)*$burgerWidth)/2);
    transform: rotate(-45deg);
  }
}

@keyframes burger-top-in {
  0% {
    left: -(floor(($burgerWidth - cos(45deg)*$burgerWidth)/2));
    top: floor(($burgerHeight - sin(45deg)*$burgerWidth)/2);
    transform: rotate(-45deg);
  }
  100% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes burger-bottom-out {
  0% {
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
  }
  100% {
    left: -(floor((($burgerWidth - cos(45deg)*$burgerWidth)/2)));
    bottom: floor(($burgerHeight - sin(45deg)*$burgerWidth)/2);
    width: $burgerWidth;
    transform: rotate(45deg);
  }
}

@keyframes burger-bottom-in {
  0% {
    left: -(floor((($burgerWidth - cos(45deg)*$burgerWidth)/2)));
    bottom: floor(($burgerHeight - sin(45deg)*$burgerWidth)/2);
    transform: rotate(45deg);
  }
  100% {
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
  }
}

@keyframes burger-middle-out {
  0% {
    left: 0px;
    width: $burgerWidth;
  }
  100% {
    left: $burgerWidth;
    width: 0;
  }
}

@keyframes burger-middle-in {
  0% {
    left: $burgerWidth;
    width: 0;
  }
  100% {
    left: 0px;
    width: $burgerWidth;
  }
}