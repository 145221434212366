body#tinymce {
  margin: 12px !important;
  font-size: 16px;
}

p a {
  color: $brand-primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.btn-default {
  background: none;
  a {
    display: inline-block;
    background-color: $brand-primary;
    border: 1px solid rgba(255,255,255,0);
    font-family: $font-family-sans-serif;
    @include uppercase();
    line-height: $headings-line-height;
    color: #fff;
    padding: 5px 10px;
    margin-bottom: 10px;
    &:hover, &:focus {
      background: none;
      border-color: $brand-primary;
      color: $brand-primary;
    }
  }
  &:hover, &:focus {
    background: none;
  }
}

.txt-large {
  font-size: 24px;
}

.txt-small {
  font-size: 14px;
}